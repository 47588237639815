import React, { useState } from 'react';
import { saveAs } from 'file-saver';
import './eindegesprek.css'; // Import the CSS file

// Access the server variable
import config from '../config';
import { getJwtToken } from '../auth';
const reactServer = config.reactServer;

interface EindeGesprekProps {
  conversationId: string;
  transcript: any[];
  transcript_dutch: any[];
  transcript_foreign: any[];
}

const EindeGesprekPage: React.FC<EindeGesprekProps> = ({ conversationId, transcript, transcript_dutch, transcript_foreign }) => {
  const [summaryType, setSummaryType] = useState('SOEP');
  const [exportType, setExportType] = useState('PDF');
  const [rating, setRating] = useState<number | null>(null);
  const [feedback, setFeedback] = useState<string>("");
  const [feedbackResponse, setFeedbackResponse] = useState(false);
  const [loadingSamenvatting, setLoadingSamenvatting] = useState(false); // New state for loading

  const handleExportGesprek = async () => {

    const token = getJwtToken();

    if (token) {
      try {
        // const conversationIdFromStorage = localStorage.getItem('conversationId');
        // console.log(exportType)
        // console.log('Conversation ID from storage:', conversationIdFromStorage);
        const response = await fetch(reactServer + 'conversation/export_transcript', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ type: exportType, id: conversationId, conversation_original: transcript, conversation_dutch: transcript_dutch, convesation_foreign: transcript_foreign }),
        });

        if (!response.ok) {
          throw new Error('Failed to export transcript');
        }

        // Get the filename from response headers
        const filename = response.headers.get('filename');

        // Convert the response to a blob
        const blob = await response.blob();

        // Save the Blob as a file
        saveAs(blob, "WellComm_Transcript_" + exportType + "_" + conversationId + "_" + filename);

      } catch (error) {
        console.error('Error exporting transcript:', error);
      }
    }
  };

  const handleSamenvatten = async () => {

    setLoadingSamenvatting(true);
    const token = getJwtToken();

    if (token) {
      try {
        // const conversationIdFromStorage = localStorage.getItem('conversationId');
        // console.log(summaryType)
        // console.log('Conversation ID from storage:', conversationId);
        const response = await fetch(reactServer + 'conversation/export_summary', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ type: summaryType, id: conversationId, conversation_dutch: transcript_dutch }),
        });

        if (!response.ok) {
          throw new Error('Failed to export transcript');
        }

        // Get the filename from response headers
        const filename = response.headers.get('filename');

        // Convert the response to a blob
        const blob = await response.blob();

        // Save the Blob as a file
        saveAs(blob, "WellComm_Summary_" + summaryType + "_" + conversationId + "_" + filename);
        setLoadingSamenvatting(false);
      } catch (error) {
        console.error('Error exporting transcript:', error);
      }
    }
  };

  const handleRatingChange = async (newRating) => {
    setRating(newRating)
    const token = getJwtToken();
  
    if (token) {
      try {
        // const conversationId = localStorage.getItem('conversationId');
        const response = await fetch(`${reactServer}conversation/update_rating`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ id: conversationId, rating: newRating }),
        });
  
        if (!response.ok) {
          throw new Error('Failed to update rating');
        }
  
        console.log('Rating updated successfully');
        // You can perform additional actions here if needed
  
      } catch (error) {
        console.error('Error updating rating:', error);
      }
    }
  };
  
  const handleFeedbackChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFeedback(event.target.value);
  };

  const handleSubmitFeedback = async () => {
    const token = getJwtToken();
  
    if (token) {
      try {
        const conversationId = localStorage.getItem('conversationId');
        const response = await fetch(`${reactServer}conversation/update_feedback`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ id: conversationId, feedback: feedback }),
        });
  
        if (!response.ok) {
          throw new Error('Failed to update feedback');
        }
        
        setFeedbackResponse(true)
        console.log('Feedback updated successfully');
        // You can perform additional actions here if needed
  
      } catch (error) {
        console.error('Error updating feedback:', error);
      }
    }
  };

  return (
    <div>
      <div className="eindegesprek-page">
        <h1>Einde Gesprek</h1>

        <div className="export-container">
          <div className='dropdown-container'>
            <label className='dropdown-label'>Export Type:</label>
            <select className="dropdown" value={exportType} onChange={(e) => setExportType(e.target.value)}>
              <option value="PDF">PDF</option>
              <option value="Text">Text</option>
            </select>
            <button className="action-button" onClick={handleExportGesprek}>Exporteer Gesprek</button>
          </div>
        </div>

        <div className='export-container'>
          <div className="dropdown-container">
            <label className='dropdown-label'>Samenvatting Type:</label>
            <select className="dropdown" value={summaryType} onChange={(e) => setSummaryType(e.target.value)}>
              <option value="SOEP">SOEP</option>
              <option value="NHG">NHG</option>
              <option value="Brief">Brief</option>
            </select>
            <button className="action-button" onClick={handleSamenvatten}>Samenvatten</button>
            {loadingSamenvatting && <div className="loader">Bezig met het maken van een samenvatting...</div>}
          </div>
        </div>

        <div className="rating-container">
          <p>Hoe ging uw gesprek?</p>
          {/* Star Rating (Replace with your actual rating component) */}
          {[1, 2, 3, 4, 5].map((value) => (
            <span key={value} className={`star ${value <= (rating ?? 0) ? 'filled' : ''}`} onClick={() => handleRatingChange(value)}>
              ★
            </span>
          ))}
          {rating &&
            <div className="feedback-container">
              <div>
              <textarea className='feedback-veld' value={feedback} onChange={handleFeedbackChange} placeholder="Geef uw feedback" />
              </div>
              <div>
              <button className="action-button" onClick={handleSubmitFeedback}>Verzenden</button>
              </div>
              {feedbackResponse &&
              <div>
                Uw feedback is verzonden. Dank u zeer.
              </div>
              }
            </div>
          }
        </div>

      </div>
      <div className='void' />
    </div>
  );
};

export default EindeGesprekPage;
