// src/views/ontwikkeling.tsx

import React from 'react';
import './infopages.css'

const InfoPage: React.FC = () => {
    return (
        <div className="faq-page">
            <h1 className="title">Ontwikkelings Updates</h1>
            {/* <div>Op deze pagina zullen de laatste updates aan het platform worden bijgehouden. Uiteraard zijn wij altijd bezig met de verdere ontwikkeling van ons platform.</div> */}


            <div className="reason">
                <h2 className="reason-title">Wellcom Portal Patch Notes 1.0.10 (17-05-2024): 💬</h2>
                <p className="reason-description">
                    Features:<br></br>
                    1. Increased the size of the microphone button for improved visibility and accessibility.<br></br>
                    2. Removed the "Over Ons" section as it duplicates information already available on the website.<br></br>
                    3. Added a language search functionality with the ability to display the 10 most commonly used languages at the beginning of the dropdown menu, starting with Arabic (Syrian-Lebanese).<br></br>
                    - Patient information in profiles now includes only patient ID for privacy reasons, omitting names and surnames. Users can choose to add this information if desired.<br></br>
                    4. When adding the web app to a phone's home screen, the title now reads "Wellcom: TolkChat" instead of "React App."<br></br>
                    5. Renamed "Start Chat" to "Start TolkChat" on the home screen.<br></br>
                    6. Renamed "Add Patient" to "Add Patiënt" on the home screen.<br></br>
                    7. Updated the site icon to reflect the current logo.<br></br>
                    8. Added a new tab titled "Demo Pilot" where a video can be uploaded. Also added a "Technical" page and FAQ section for additional information.<br></br>
                    <br></br>
                    Bug Fixes:<br></br>
                    - Implemented various bug fixes and optimizations.<br></br>
                    - Designed a new logo for TolkChat.<br></br>
                    - Added information to the FAQ pages.<br></br>
                </p>
            </div>





            <div className="reason">
                <h2 className="reason-title">WellCom Portaal Patch Notes 1.0.9 (17-05-2024): 🗣✅</h2>
                <p className="reason-description">
                    Features:<br></br>
                    - Enabled Speech to Text functionality with support for 148 different languages and dialects, enhancing accessibility and usability.<br></br>
                    - Expanded compatibility to include all MacBooks, ensuring seamless usage across various devices, including older models like Victor's.<br></br>
                    - Updated all instances of "WellCom" to "Wellcom" for consistency throughout the platform.<br></br>
                    - Implemented additional optimizations for faster loading of images and other content, improving overall user experience.<br></br>
                    - Improved the mobile version's appearance for a more polished and user-friendly interface.<br></br>
                    <br></br>
                    Enhancements:<br></br>
                    - Continued backend optimizations to enhance system efficiency and performance.<br></br>
                    <br></br>
                    Bug Fixes:<br></br>
                    - Addressed various bugs and streamlined processes to ensure smooth and uninterrupted service for all users.
                </p>
            </div>


            <div className="reason">
                <h2 className="reason-title">WellCom Portaal Patch Notes 1.0.8 (16-04-2024): 💬</h2>
                <p className="reason-description">
                    Features:<br></br>
                    - Implemented two-step verification emails for enhanced security, ensuring users' accounts remain protected.<br></br>
                    - Optimized image loading times by reducing picture sizes through compression techniques, resulting in faster page loading.<br></br>
                    - Addressed a critical bug stemming from a database migration, resolving numerous issues and restoring full functionality to the platform.<br></br>
                    - Implemented server-side clearance for improved system performance and stability.<br></br>
                    - Enhanced user experience by starting the page directly at the login screen, eliminating unnecessary prompts.<br></br>
                    <br></br>
                    Enhancements:<br></br>
                    - Various backend optimizations and improvements to enhance overall system efficiency.<br></br>
                    <br></br>
                    Bug Fixes:<br></br>
                    - Resolved critical errors and issues to ensure uninterrupted service for all users.
                </p>
            </div>

        </div>
    );
};

export default InfoPage;
