import React, { useState, useEffect } from 'react';

const TranslationPage: React.FC = () => {
    const [inputText, setInputText] = useState('');
    const [translatedText, setTranslatedText] = useState('');
    const [fromLanguage, setFromLanguage] = useState('English');
    const [toLanguage, setToLanguage] = useState('Nederlands');

    const allLanguages = ['Nederlands', 'English', 'German', 'French', 'Arabic', 'Turkish', 'Spanish', 'Polish', 'Chinese (Mandarin)', 'Hindi', 'Portuguese', 'Ukrainian'];
    const limitedLanguages = ['Nederlands', 'English'];

    const languageMapping = {
        'Nederlands': 'nl',
        'English': 'en',
        'German': 'de',
        'French': 'fr',
        'Arabic': 'ar',
        'Turkish': 'tr',
        'Spanish': 'es',
        'Polish': 'pl',
        'Chinese (Mandarin)': 'zh',
        'Hindi': 'hi',
        'Portuguese': 'pt',
        'Ukrainian': 'uk'
    };

    useEffect(() => {
        if (inputText) {
            const fromLangCode = languageMapping[fromLanguage];
            const toLangCode = languageMapping[toLanguage];
            translateText(inputText, fromLangCode, toLangCode);
        }
    }, [inputText, fromLanguage, toLanguage]);

    const translateText = async (text: string, fromLanguage: string, toLanguage: string) => {
        try {
            const response = await fetch('http://localhost:5000/translation/translate', { // Replace with your Flask server URL
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ 
                    text: text, 
                    source_language: fromLanguage, 
                    target_language: toLanguage 
                })
                
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            setTranslatedText(data.translated_text);
        } catch (error) {
            console.log(text, fromLanguage, toLanguage)
            console.error("There was an error translating the text: ", error);
        }
    };

    const swapLanguages = () => {
        setFromLanguage(toLanguage);
        setToLanguage(fromLanguage);
        setInputText(translatedText);
        setTranslatedText(inputText);
    };

    const getLanguageOptions = (currentSelection: string) => {
        if (currentSelection === 'Nederlands' || currentSelection === 'English') {
            return allLanguages;
        }
        return limitedLanguages;
    };

    return (
        <div className="translation-page">
            <h1 className="translation-title">Vertaal uw medische tekst</h1>

            <div className="language-selectors">
                <select
                    value={fromLanguage}
                    onChange={(e) => setFromLanguage(e.target.value)}
                >
                    {getLanguageOptions(toLanguage).map(lang => (
                        <option key={lang} value={lang}>{lang}</option>
                    ))}
                </select>

                <button className="swap-button" onClick={swapLanguages}>Swap</button>

                <select
                    value={toLanguage}
                    onChange={(e) => setToLanguage(e.target.value)}
                >
                    {getLanguageOptions(fromLanguage).map(lang => (
                        <option key={lang} value={lang}>{lang}</option>
                    ))}
                </select>
            </div>

            <textarea
                className="input-text"
                placeholder="Enter text to translate"
                value={inputText}
                onChange={(e) => setInputText(e.target.value)}
            />

            <textarea
                className="output-text"
                value={translatedText}
                readOnly
            />
        </div>
    );
};

export default TranslationPage;
