import React, { useState, useEffect } from 'react';
import { getJwtToken } from '../auth';
import config from '../config';
const reactServer = config.reactServer;

const UserOverview: React.FC = () => {
    const [users, setUsers] = useState<any[]>([]);
    const [subscriptionType, setSubscriptionType] = useState<string>('no');
    const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
    const [subscriptionEndDate, setSubscriptionEndDate] = useState<string>('');

    // Get all the data from the user
    const [profile, setProfile] = useState({ is_admin: false });

    useEffect(() => {
        const token = getJwtToken();

        if (token) {
            fetch(reactServer + 'account/profile', {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                    }
                    return response.json();
                })
                .then(data => {
                    setProfile(data);
                })
                .catch(error => {
                    console.error('Error fetching profile:', error);
                });
        }
    }, []);

    // get the data from all the users
    useEffect(() => {
        const token = getJwtToken();

        fetch(reactServer + 'account/users', {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => setUsers(data))
            .catch(error => console.error('Error fetching users:', error));
    }, []);

    const handleSubscriptionUpdate = () => {
        if (!selectedUserId) {
            console.error('Please select a user');
            return;
        }

        const token = getJwtToken();

        fetch(reactServer + 'account/update_subscription', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ userId: selectedUserId, subscriptionType, subscriptionEndDate })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to update subscription');
                }
                return response.json();
            })
            .then(() => {
                // Reload the page after successful subscription update
                window.location.reload();
            })
            .catch(error => console.error('Error updating subscription:', error));
    };

    return (
        <div className="profile-container">
            {!profile.is_admin && <div className="profile-page">Pagina alleen beschikbaar voor administratoren.</div>}
            {profile.is_admin &&
                <div className="user-overview">

                    <div className="subscription-form">
                        <h2 className='title'>Update Subscription</h2>
                        <select value={selectedUserId} onChange={(e) => setSelectedUserId(parseInt(e.target.value))}>
                            <option value={null}>Select a user</option>
                            {users.map(user => (
                                <option key={user.id} value={user.id}>{user.naam}</option>
                            ))}
                        </select>
                        <select value={subscriptionType} onChange={(e) => setSubscriptionType(e.target.value)}>
                            <option value="no">No</option>
                            <option value="lite">Lite</option>
                            <option value="pro">Pro</option>
                        </select>
                        <input
                            type="date"
                            value={subscriptionEndDate}
                            onChange={(e) => setSubscriptionEndDate(e.target.value)}
                        />
                        <button onClick={handleSubscriptionUpdate}>Update Subscription</button>
                    </div>

                    <h2 className="title">User Overview</h2>
                    <table className='users-table'>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Admin</th>
                                <th>Geregistreerd</th>
                                <th>Gebruik</th>
                                <th>Abbonement</th>
                                {/* Add other table headers for additional user attributes */}
                            </tr>
                        </thead>
                        <tbody>
                            {users.map(user => (
                                <tr key={user.id}>
                                    <td>{user.id}</td>
                                    <td>{user.naam}</td>
                                    <td>{user.email}</td>
                                    <td>{user.admin ? 1 : 0}</td>
                                    <td>{user.registratie ? 1 : 0}</td>
                                    <td>{user.usage}</td>
                                    <td>{user.subscription[0] === 'no' ? 'Nee' : user.subscription[0]} {user.subscription[1] === '21-01-2000' ? 'nog niet' : 'tot ' + user.subscription[1]}</td>
                                    {/* Add other table cells for additional user attributes */}
                                </tr>
                            ))}
                        </tbody>
                    </table>

                </div>
            }
        </div>
    );
};

export default UserOverview;
