import { FFmpeg } from '@ffmpeg/ffmpeg';
import { fetchFile, toBlobURL } from '@ffmpeg/util';

const transcodeAudio = async (audioFileUrl, speaker) => {
    console.log('Starting the transcoding process...');

    try {
        const ffmpeg = new FFmpeg();
        const baseURL = 'https://unpkg.com/@ffmpeg/core@0.12.6/dist/umd';

        await ffmpeg.load({
            coreURL: await toBlobURL(`${baseURL}/ffmpeg-core.js`, 'text/javascript'),
            wasmURL: await toBlobURL(`${baseURL}/ffmpeg-core.wasm`, 'application/wasm'),
        });
        console.log('FFmpeg core loaded.');

        // Write the audio file to FFmpeg's virtual file system
        await ffmpeg.writeFile('input.webm', await fetchFile(audioFileUrl));
        console.log('Audio file written to FFmpeg virtual file system.');

        // Run the FFmpeg command to transcode the webm file to wav
        await ffmpeg.exec(['-i', 'input.webm', 'recording.wav']);
        console.log('FFmpeg transcoding command executed.');

        // Read the transcoded wav file
        const data = await ffmpeg.readFile('recording.wav');
        console.log('Transcoded wav file read.');

        // Create a blob from the transcoded wav file
        const wavBlob = new Blob([data.buffer], { type: 'audio/wav' });
        console.log('Blob created from transcoded wav file.');

        console.log('Transcoding process completed successfully.');
        return wavBlob;
    } catch (error) {
        console.error('Error during transcoding:', error);
        throw error;
    }
};

export default transcodeAudio;
