import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import './Registration.css'

import config from '../config';
const reactServer = config.reactServer;


const LoginForm: React.FC = () => {
  const [email, setEmail] = useState('');
  const [wachtwoord, setWachtwoord] = useState('');
  const [error, setError] = useState('');
  const [twoStepAvailable, setTwoStepAvailable] = useState(false);
  const [twoStepCode, setTwoStepCode] = useState('')

  const navigate = useNavigate();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formData = {
      email: email,
      wachtwoord: wachtwoord,
    };

    const response = await fetch(reactServer + 'account/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      if (response.status === 401) {
        setError('Foutief wachtwoord.');
      } else if (response.status === 404) {
        setError('Account niet gevonden.')
      }
      else {
        setError('Geen verbinding met de server.');
      }
      return;
    }

    const data = await response.json();
    console.log(data)

    if (data.message === 'Login successful') {
      console.log('Succesfull login')
      const token = data.access_token; // Adjust this based on your backend response
      // Store the token in localStorage
      localStorage.setItem('jwtToken', token);

      navigate('/', { replace: true });
      window.location.reload();
    } else {
      setError(data.message);
    }
  };

  return (
    <div className='login-container'>
      <h1 className="page-title">Login</h1>
      <form className="registration-form" onSubmit={handleSubmit}>
        <input
          type="email"
          name="email"
          placeholder="Email"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
        />
        <input
          type="password"
          name="wachtwoord"
          placeholder="Wachtwoord"
          value={wachtwoord}
          onChange={(event) => setWachtwoord(event.target.value)}
        />
        <button type="submit">Login</button>
        {error && <p style={{ color: 'red' }}>{error}</p>}
        {/* {twoStepAvailable && 
        <div>
          <label style={{ marginTop: '15px' }} htmlFor="termsAndServices">Uw tweestaps verificatie code:</label>
          <input
          type="code"
          name="code"
          placeholder="Code"
          value={twoStepCode}
          onChange={(event) => setTwoStepCode(event.target.value)}
          />
          <button type="submit">Bevestigen</button>
        </div>
        } */}
      </form>
      <div className='same-line login-pagina-tekst'>
        Heb je nog geen account? <Link to="/register">Registreren.</Link>
      </div>
    </div>
  );
};

export default LoginForm;