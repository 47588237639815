import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import '../App.css'; // Import the CSS file
import './profiel.css'; // Import the custom CSS file for profile page

import { getJwtToken, logout } from '../auth';
import { allLanguages, TranslateMapping, SpeechToTextMapping, TextToSpeechMapping } from '../mappings/microsoft';

// Access the server variable
import config from '../config';
const reactServer = config.reactServer;

const ProfilePage: React.FC = () => {
    const [userData, setUserData] = useState<any>(null); // State to store user data
    const [newPatientName, setNewPatientName] = useState<string>('');
    const [newPatientSurname, setNewPatientSurname] = useState<string>('');
    const [newPatientLanguage, setNewPatientLanguage] = useState<string>('');
    const [searchPatientName, setSearchPatientName] = useState<string>('');
    const [selectedPatient, setSelectedPatient] = useState<any>(null);
    const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);
    const [rownaam, setRowNaam] = useState('')
    const [selectedOption, setSelectedOption] = useState('naam'); // Default to 'naam'
    const [newPatientID, setNewPatientID] = useState('');
    const navigate = useNavigate();

    // Get all the data from the user
    const [profile, setProfile] = useState(null);

    useEffect(() => {
        const token = getJwtToken();

        if (token) {
            fetch(reactServer + 'account/profile', {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                    }
                    return response.json();
                })
                .then(data => {
                    setProfile(data);
                    console.log(data)
                    console.log(data.patienten)
                    console.log(data.naam)
                    console.log(data.usage)
                })
                .catch(error => {
                    console.error('Error fetching profile:', error);
                });
        }
    }, []);


    useEffect(() => {
        // Simulate fetching user data when component mounts
        const fakeUserData = {
            naam: 'Thijs Rood',
            email: 'thijs.rood@example.com',
            accounttype: 'dokter', // Change to 'patient' for patient
            RegistrationConfirmed: true,
            twofactor: ['123456', '30-01-2024 15:20:10'],
            patients: [
                { naam: 'Jim', surname: 'Vechtold', language: 'English' },
                { naam: 'Jan', surname: 'de Weert', language: 'Spanish' },
                { naam: 'Charles', surname: 'den Tex', language: 'French' }
            ]
        };
        setUserData(fakeUserData);
    }, []);

    const handleAddPatient = async () => {
        const newPatient = {
            naam: newPatientName,
            surname: newPatientSurname,
            language: newPatientLanguage
        };

        try {
            const token = getJwtToken();

            const response = await fetch(reactServer + 'account/add-patient', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newPatient),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const updatedProfile = await response.json();
            console.log('aashdahdsa')


            // Update the local state with the updated profile data
            setProfile(updatedProfile);
        } catch (error) {
            console.error('Error adding patient:', error);
        }

        // update live on the page too
        const updatedPatients = [...userData.patients, newPatient];
        setUserData({ ...userData, patients: updatedPatients });

        // Clear input fields after adding patient
        setNewPatientName('');
        setNewPatientSurname('');
        setNewPatientLanguage('');
    };

    const handleDeletePatient = async (patientId) => {
        try {
            const token = getJwtToken();

            const response = await fetch(reactServer + `account/delete-patient/${patientId}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const updatedProfile = await response.json();

            // Update the local state with the updated profile data
            setProfile(updatedProfile);

            // Update the local state to remove the deleted patient
            const updatedPatients = userData.patients.filter(patient => patient.id !== patientId);
            setUserData({ ...userData, patients: updatedPatients });
        } catch (error) {
            console.error('Error deleting patient:', error);
        }
    };


    const handleSearchPatient = () => {
        // Simulate searching for a patient
        const foundPatient = userData.patients.find((patient: any) =>
            `${patient.naam} ${patient.surname}` === searchPatientName
        );
        setSelectedPatient(foundPatient);
    };

    const handleRowClick = (index: number, naam: string) => {
        setSelectedRowIndex(index === selectedRowIndex ? null : index);
        setRowNaam(naam);
    };

    const handleStartConversation = () => {
        // Simulate starting a conversation with the selected patient
        console.log(`Starting conversation with ${selectedPatient.naam} ${selectedPatient.surname}`);
    };

    const handleLogout = () => {
        // Simulate logout action, you can implement your actual logout logic here
        logout();
        navigate('/', { replace: true });
        window.location.reload();
        console.log('Logged out');
    };


    return (
        <div className="profile-page-container">
            {userData && profile && (
                <>
                    {/* Left Block: User Details */}
                    <div className="user-details-block">
                        <h2 className="title">{profile.naam}'s Profile</h2>
                        <p>Email adres: {profile.email}</p>
                        {/* <p>Account type: {userData.accounttype}</p> */}

                        <p>Aantal patienten: {profile.patienten.length}</p>
                        <p>Gebruik: {profile.usage}/∞</p>
                        {/* <p>Abbonement type: {profile.subscription[0]} tot {profile.subscription[1]}</p> */}
                        <p>Registratie bevestigd: {userData.RegistrationConfirmed ? 'Ja' : 'Nee'}</p>
                        <button className="logout-button" onClick={handleLogout}>Uitloggen</button>

                        <hr className='patient-line' />
                        <div>
                            <h3 className='patient-title'>Voeg Patient Toe</h3>
                            <div className='centerme'>
                                
                                    <div className="radio-buttons">
                                        <label>
                                            <input
                                                type="radio"
                                                value="naam"
                                                checked={selectedOption === 'naam'}
                                                onChange={(e) => setSelectedOption(e.target.value)}
                                            />
                                            Naam
                                        </label>
                                        <label>
                                            <input
                                                type="radio"
                                                value="patientID"
                                                checked={selectedOption === 'patientID'}
                                                onChange={(e) => setSelectedOption(e.target.value)}
                                            />
                                            PatientID
                                        </label>
                                    </div>
                                    <div className="patient-inputs">
                                    {selectedOption === 'naam' ? (
                                        <>
                                            <input
                                                type="text"
                                                className='patient-input'
                                                placeholder="Voornaam"
                                                value={newPatientName}
                                                onChange={(e) => setNewPatientName(e.target.value)}
                                            />
                                            <input
                                                type="text"
                                                className='patient-input'
                                                placeholder="Achternaam"
                                                value={newPatientSurname}
                                                onChange={(e) => setNewPatientSurname(e.target.value)}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <input
                                                type="text"
                                                className='patient-input'
                                                placeholder="PatientID"
                                                value={newPatientName}
                                                onChange={(e) => setNewPatientName(e.target.value)}
                                            />
                                        </>
                                    )}

                                    <select
                                        className='selectorpatient'
                                        value={newPatientLanguage}
                                        onChange={(e) => setNewPatientLanguage(e.target.value)}
                                    >
                                        <option value="">Selecteer taal</option>
                                        {allLanguages.map(lang => (
                                            <option key={lang} value={lang}>{lang}</option>
                                        ))}
                                    </select>
                                </div>
                                <button className="add-patient-button" onClick={handleAddPatient}>Toevoegen</button>
                            </div>
                        </div>
                    </div>


                    {/* Right Block: Add/Search Patient Section */}
                    {userData.accounttype === 'dokter' && (
                        <div className="add-patient-block">

                            <h3>Zoek Patient</h3>
                            <div className="patient-search-inputs">
                                <input
                                    type="text"
                                    className='inputfield'
                                    placeholder="Patient naam"
                                    value={searchPatientName}
                                // onChange={(e) => setSearchPatientName(e.target.value)}
                                />
                                <button className="add-patient-button" onClick={handleSearchPatient}>Zoek</button>
                            </div>
                            {selectedPatient && (
                                <div className="selected-patient-details">
                                    <p>Selected Patient: {selectedPatient.naam} {selectedPatient.surname}</p>
                                    <button className="start-conversation-button" onClick={handleStartConversation}>Start Gesprek</button>
                                </div>
                            )}
                            <table className="patient-table">
                                <thead>
                                    <tr>
                                        <th>Naam</th>
                                        <th>Taal</th>
                                        <th>Geregistreerd</th>
                                        <th>Verwijderen</th>
                                        {/* Add more table headers as needed */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {profile.patienten.map((patient: any, index: number) => (
                                        <tr
                                            key={index}
                                            onClick={() => handleRowClick(index, patient.naam + ' ' + patient.surname)}
                                            className={index === selectedRowIndex ? 'selected' : ''}
                                        >
                                            <td>{patient.naam} {patient.surname}</td>
                                            <td>{patient.language}</td>
                                            <td>{patient.RegistrationConfirmed ? 'Ja' : 'Nee'}</td>
                                            <td className='bincontainer'>
                                                <img
                                                    className={'bin'}
                                                    src={"/bin_black.png"} // : "/microphone.png"}
                                                    onMouseOver={(e) => e.currentTarget.src = "/bin_red.png"}
                                                    onMouseOut={(e) => e.currentTarget.src = "/bin_black.png"}
                                                    // alt="microphone"
                                                    onClick={() => handleDeletePatient(index)}
                                                />
                                            </td>
                                            {/* Add more table data cells as needed */}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            {rownaam && <div>De naam van de gebruiker die moet laden is {rownaam}</div>}
                        </div>
                    )}
                </>
            )
            }
        </div >
    );
};

export default ProfilePage;
