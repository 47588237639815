// src/views/ConversationHistory.tsx

import React, { useState, useEffect } from 'react';

const ConversationHistory: React.FC = () => {


    return (
        <div className="about-page">
            <div className='text-box'>
                <h1 className="about-title">Gesprek geschiedenis</h1>
                <p className="description">
                    Hier moet uiteindelijk de gespreksgeschiedenis komen te staan in boxes
                </p>
                <p className="description">
                    Bij WellCom© begrijpen we het belang van de beveiliging van patiëntgegevens. Ons programma werkt lokaal, 
                    zodat gevoelige informatie nooit opgeslagen of verwerkt wordt door grote bedrijven. De privacy en 
                    veiligheid van patiëntgegevens staan bij ons voorop.
                </p>
            </div>
        </div>
    );
};

export default ConversationHistory;
