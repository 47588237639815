import React, { useState } from 'react';

import './Registration.css'
import { useNavigate, BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

import config from '../config';
const reactServer = config.reactServer;


const RegistrationForm: React.FC = () => {
  const [naam, setNaam] = useState('');
  const [email, setEmail] = useState('');
  const [wachtwoord, setWachtwoord] = useState('');
  const [herhaal, setHerhaal] = useState('');
  const [termsAndServices, setTermsAndServices] = useState(false);
  const [twoStepAvailable, setTwoStepAvailable] = useState(false);
  const [twoStepCode, setTwoStepCode] = useState('');
  const [error, setError] = useState('');
  const [succes, setSucces] = useState('');
  const [organization, setOrganization] = useState('');
  const [contractFile, setContractFile] = useState<File | null>(null);


  const navigate = useNavigate();

  const validateNumbers = (value: string) => {
    const regex = /^[0-9]+$/;
    return regex.test(value);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formData = {
      naam: naam,
      email: email,
      wachtwoord: wachtwoord,
      termsAndServices: termsAndServices,
      organization: organization,
      contractFile: contractFile,
    };

    console.log(formData)

    // Iterate over the form fields and check if each field is empty
    for (const [key, value] of Object.entries(formData)) {
      if (value === '') {
        // Display an error message to the user and prevent the form from being submitted
        setError('Alle velden dienen ingevuld te worden');
        return;
      }
    }

    if (herhaal !== wachtwoord) {
      // Set the error message
      setError('Wachtwoorden komen niet overeen.');
      return;
    }

    if (!email.includes('@')) {
      // Set the error message
      setError('Dit is geen geldig e-mailadres');
      return;
    }


    if (!termsAndServices) {
      // Set the error message
      setError('Je moet akkoord gaan met de terms en services.');
      return;
    }

    const response = await fetch(reactServer + 'account/register', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      // The response was successful
      setError('Geen verbinding met de server.')
    }

    const data = await response.json();

    if (data.message === 'Verificatiemail verzonden') {
      // setSucces(data.message)
      setTwoStepAvailable(true);

      localStorage.setItem("email", email);
      // navigate('/login', { replace: true });
      // window.location.reload();
    }
    else {
      setError(data.message)
    }
  };

  const handleVerification = async (email: string, code: string) => {
    const data = {
      email: email,
      code: code,
    };

    const response = await fetch(reactServer + 'account/verify', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    if (response.ok) {
      // Verification successful
      // You can redirect the user to the login page or any other desired action
      navigate('/login', { replace: true });
    } else {
      // Verification failed, handle the error
      const responseData = await response.json();
      setError(responseData.message);
    }
  };

  const handleVerificationSubmit = async () => {
    await handleVerification(email, twoStepCode);
  };

  const handleContractFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      setContractFile(files[0]);
    }
  };

  return (
    <div className="registration-container">
      <h1 className="page-title">Registratie</h1>
      <form className="registration-form" onSubmit={handleSubmit}>
        <input
          type="naam"
          name="naam"
          placeholder="Volledige naam"
          value={naam}
          onChange={(event) => setNaam(event.target.value)}
        />
        <input
          type="email"
          name="email"
          placeholder="Email"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
        />
        <input
          type="password"
          autoComplete="new-password"
          name="wachtwoord"
          placeholder="Wachtwoord"
          value={wachtwoord}
          onChange={(event) => setWachtwoord(event.target.value)}
        />
        <input
          type="password"
          autoComplete="new-password"
          name="herhaal"
          placeholder="Herhaal wachtwoord"
          value={herhaal}
          onChange={(event) => setHerhaal(event.target.value)}
        />
        {/* <label htmlFor="accounttype">Ik meld mij aan als:</label> */}

        {/* <label htmlFor="organization">Organisatie (optioneel)</label> */}
        <input
          type="text"
          name="organization"
          placeholder="Organisatie (Optioneel)"
          value={organization}
          onChange={(event) => setOrganization(event.target.value)}
        />
        {/* <label htmlFor="contractFile">Upload getekend contract (PDF)</label>
        <a href="/path/to/contract-template.pdf" download>
          Download contract template
        </a>
        <input
          type="file"
          name="contractFile"
          accept=".pdf"
          onChange={handleContractFileChange}
        /> */}

        <label htmlFor="termsAndServices">I accept the terms and services.*</label>
        <input
          type="checkbox"
          name="termsAndServices"
          checked={termsAndServices}
          onChange={(event) => setTermsAndServices(event.target.checked)}
        />
        <button type="submit">Registreren</button>
        {error && <p style={{ color: 'red' }}>{error}</p>}
        {succes && <p style={{ color: 'black' }}>{succes}</p>}
        {twoStepAvailable &&
          <div>
            <label style={{ marginTop: '15px' }} htmlFor="termsAndServices">We hebben u een mail verzonden met een verificatiecode (check uw spam folder). Uw tweestaps verificatie code:</label>
            <input
              type="code"
              name="code"
              placeholder="Code"
              className='twostepcode'
              value={twoStepCode}
              onChange={(event) => setTwoStepCode(event.target.value)}
            />
            <button type="button" onClick={handleVerificationSubmit}>Bevestigen</button>
          </div>
        }
      </form>
    </div>
  );
};

export default RegistrationForm;