const extralanguageMapping = {
    'chiShona': 'chiShona (Zimbabwe)',
    'Dari': 'Dari (Afghanistan)',
    'English': 'English (US - EN and AUS)',
    'Galician': 'Galician (Spanish)',
    'Hausa': 'Hausa (Niger/Nigeria)',
    'Igbo': 'Igbo (Niger)',
    'Kinyarwanda': 'Kinyarwanda (Rwanda)',
    'Lingala': 'Lingala (Congo)',
    'Luganda': 'Luganda (Uganda)',
    'Nyanja': 'Nyanja (Malawi, Mozambique, Zimbabwe, Zambia)',
    'Odia': 'Odia (Indian)',
    'Persian': 'Farsi (Iran)',
    'Rundi': 'Rundi (Burundi)',
    'Sindhi': 'Sindhi (Pakistan)',
    'Tatar': 'Tatar (Poland-Russia)',
    'Tigrinya': 'Tigrinya (Ethiopian)',
    'Yoruba': 'Yoruba (Nigeria)',
    'Sesotho': 'Sesotho (South-Africa)',
    'Sesotho sa Leboa': 'Sesotho sa Leboa (South-Africa)',
    'Setswana': 'Setswana (South-Africa)'
};

export default extralanguageMapping;
