// src/App.tsx
import React, { useState, useEffect } from 'react';
import { HashRouter as Router, Routes, Route, Link, useLocation } from 'react-router-dom';

import LogoAnimation from './logoAnimation';
import TolkPage from './tolkchat';
// import TranslationPage from './translation';
import AboutPage from './pages/about';
import ContactPage from './pages/contact';
import WaaromPage from './pages/waarom';
import LoginPage from './pages/login';
import RegisterPage from './pages/register';
import History from './pages/history';
import Domein from './pages/profiel';
import Abonnementen from './pages/abbonement';
import Contract from './pages/contract';
import ProfielOverzicht from './pages/profieloverzicht';
import FAQ from './pages/faq';
import Ontwikkeling from './pages/ontwikkeling';
import DemoPilot from './pages/demopilot';
import Feedback from './pages/contactfeedback';

import './App.css'; // Import your main App stylesheet here

import { getJwtToken } from './auth';

// Access the server variable
import config from './config';
import TranslationPage from './translation';
import ContactFeedbackPage from './pages/contactfeedback';
const reactServer = config.reactServer;

const App: React.FC = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [animationFinished, setAnimationFinished] = useState(false);
  const [isInTolkChat, setIsInTolkChat] = useState(false); // New state to track if user is in Tolk Chat page

  const [currentPath, setCurrentPath] = useState<string>(window.location.pathname);

  const handleAnimationEnd = () => {
    setAnimationFinished(true);
  };

  useEffect(() => {
    console.log('hier:' + currentPath)
    if (currentPath.includes('/tolkchat')) {
      setIsInTolkChat(true);
    } else {
      setIsInTolkChat(false);
    }
  }, [currentPath]);


  // Get all the data from the user
  const [profile, setProfile] = useState({ is_admin: false, naam: false });

  useEffect(() => {
    const token = getJwtToken();

    if (token) {
      fetch(reactServer + 'account/profile', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then(data => {
          setProfile(data);
        })
        .catch(error => {
          console.error('Error fetching profile:', error);
        });
    }
  }, []);


  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScrolled(isScrolled);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const handleNavigation = (path: string, event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (isInTolkChat) {
      const confirmNavigation = window.confirm('Uw gespreksdata zal verloren gaan. Weet u zeker dat je het huidige gesprek wilt afsluiten?');
      if (!confirmNavigation) {
        event.preventDefault(); // Prevents the default behavior of the link
      } else {
        setCurrentPath(path);
      }
    } else {
      setCurrentPath(path);
    }
  };



  return (
    <div>
      {animationFinished === false && (<LogoAnimation onAnimationEnd={handleAnimationEnd} />)}
      {animationFinished && (
        <Router>
          <div>
            <nav className={`navigation ${scrolled ? 'scrolled' : ''}`}>
              {/* <Link className="nav-link show-on-mobile" to="/translate" onClick={(e) => handleNavigation('/translate', e)}>Translate</Link> */}
              {profile.is_admin ? (<Link className="nav-link" to="/profieloverzicht" onClick={(e) => handleNavigation('/profieloverzicht', e)}>Profieloverzicht</Link>) : (<Link className="nav-link" to="/waarom" onClick={(e) => handleNavigation('/waarom', e)}>Waarom Wellcom?</Link>)}
              {/* <Link className="nav-link show-on-mobile" to={profile.naam ? "/tolkchat" : "/login"} onClick={(e) => handleNavigation('/contact', e)}>Contact</Link> */}
              <Link className="nav-link" to={profile.naam ? "/feedback" : "/contact"} onClick={(e) => handleNavigation(profile.naam ? "/feedback" : "/contact", e)} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>{profile.naam ? "Feedback" : "Contact"}</Link>
              <Link className="nav-link show-on-mobile-only" to={"/tolkchat"} >Tolk Chat</Link>
              <Link className="nav-link" to="/abonnement" onClick={(e) => handleNavigation('/abonnement', e)}>Abonnement</Link>
              <Link to={profile.naam ? "/tolkchat" : "/login"} >
                {/* onClick={(e) => handleNavigation(profile.naam ? '/tolkchat' : '/login', e)} */}
                <img src='./logo.png' alt="Logo" className="nav-logo" />
              </Link>
              <Link className="nav-link" to={profile.naam ? "/tolkchat" : "/login"} >
                {/* onClick={(e) => handleNavigation(profile.naam ? '/tolkchat' : '/login', e)} */}
                Tolk Chat
              </Link>
              {/* <Link className="nav-link" to="/contract">Contract status</Link> */}

              {/* <Link className='nav-link' to="/domein" onClick={(e) => handleNavigation('/domein', e)}>Profiel</Link> */}
              <Link className="nav-link" to={profile.naam ? "/domein" : "/faq"} onClick={(e) => handleNavigation(profile.naam ? "/domein" : "/faq", e)} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>{profile.naam ? 'Profiel' : "FAQ"}</Link>

              {/* <Link className="nav-link show-on-mobile" to={name ? "/domein" : "/login"} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false) }>{name ? (isHovered ? "Profiel" : name) : "Login"}</Link> */}
              <Link className="nav-link show-on-mobile" to={profile.naam ? "/domein" : "/login"} onClick={(e) => handleNavigation(profile.naam ? "/domein" : "/login", e)} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>{profile.naam ? (isHovered ? profile.naam : profile.naam) : "Login"}</Link>


            </nav>

            <div className='main-content'>
              <Routes>
                <Route path="/" element={profile.naam ? <TolkPage /> : <LoginPage />} />
                {/* <Route path="/about" element={<AboutPage />} /> */}
                <Route path="/contact" element={<ContactPage />} />
                <Route path="/waarom" element={<WaaromPage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/register" element={<RegisterPage />} />
                <Route path="/tolkchat" element={<TolkPage />} />
                <Route path="/history" element={<History />} />
                <Route path="/domein" element={<Domein />} />
                <Route path="/abonnement" element={<Abonnementen />} />
                <Route path="/contract" element={<Contract />} />
                <Route path='/profieloverzicht' element={<ProfielOverzicht />} />
                <Route path='/translate' element={<TranslationPage />} />
                <Route path='/faq' element={<FAQ />} />
                <Route path='/ontwikkeling' element={<Ontwikkeling />} />
                <Route path='/demopilot' element={<DemoPilot />} />
                <Route path='/feedback' element={<Feedback />} />
              </Routes>
            </div>
          </div>

          <footer className="footer">
            <div className="social-links">
              <a href="https://www.linkedin.com/company/wellcom-translation/" target="_blank" rel="noopener noreferrer">
                <img src="./linkedin-logo.png" alt="LinkedIn" />
              </a>
              <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
                <img src="./instagram-logo.png" alt="Instagram" />
              </a>
            </div>
            <div className="additional-links">
              <Link to="/faq">FAQ</Link>
              <Link to="/ontwikkeling">Ontwikkeling</Link>
              <Link to="/demopilot">Demo Pilot</Link>
            </div>
            <div className="copyright">
              &copy; {new Date().getFullYear()} Wellcom. all rights reserved.
            </div>
          </footer>
        </Router>)}
    </div>
  );
}

export default App;
