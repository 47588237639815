// src/views/faq.tsx

import React from 'react';
import './infopages.css'

const InfoPage: React.FC = () => {
    return (
        <div className="faq-page">
            <h1 className="title">Frequently Asked Questions</h1>
            
            <div className="reason">
                <h2 className="reason-title">Wat is Wellcom?</h2>
                <p className="reason-description">
                    Wellcom is een platform waarmee de medische wereld toegankelijker wordt voor mensen met een andere taalachtergrond. Door middel van verschillende oplossingen proberen we er voor te zorgen dat patïenten makkelijker contact kunnen hebben met hun artsen.
                </p>
            </div>
            <div className="reason">
                <h2 className="reason-title">Is het platform beveiligd? Worden patiëntgegevens verwerkt?</h2>
                <p className="reason-description">
                    De app werkt lokaal om de privacy en veiligheid van patiëntgegevens te waarborgen. De enige patiëntdata die bijgehouden kan worden is of een zelfgekozen naam/identificatienummer en de taal van de patiënt. Op deze manier waarborgen we anonimiteit.
                </p>
            </div>
            <div className="reason">
                <h2 className="reason-title">Wat is de TolkChat?</h2>
                <p className="reason-description">
                    De TolkChat is een van onze oplossingen. Hiermee kunnen een arts en een patiënt een gesprek met elkaar voeren waarbij er teruggekeken kan worden naar het gesprek als een diagloog. Ook is het door middel van ons eindscherm mogelijk om samenvattingen te maken voor de arts op de gewenste manier.
                </p>
            </div>
        </div>
    );
};

export default InfoPage;
