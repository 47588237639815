import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './tolkchat.css'

import Eindscherm from './pages/eindegesprek';
import LanguageSelector from './pages/languageselector';

// langauge mappings
import { allLanguages, TranslateMapping, SpeechToTextMapping, TextToSpeechMapping } from './mappings/microsoft';
import { DeeplallLanguages, DeepllanguageMapping } from './mappings/deepl';

import transcodeAudio from './functions/transcodingFFmpeg';

// Access the server variable
import { getJwtToken } from './auth';
import config from './config';
const reactServer = config.reactServer;

const ChatPage: React.FC = () => {
    const [originalMessages, setOriginalMessages] = useState<{ text: string; className: string; mobileClass: string; }[]>([]);
    const [leftMessages, setleftMessages] = useState<{ text: string; className: string; mobileClass: string; }[]>([]);
    const [rightMessages, setrightMessages] = useState<{ text: string; className: string; mobileClass: string; }[]>([]);
    const [allMessages, setallMessages] = useState<{ text: string; className: string; mobileClass: string; }[]>([]);
    const [error, setError] = useState('');
    const [conversationId, setConversationId] = useState(1);
    const [currentMessage, setCurrentMessage] = useState('');
    const [currentMessageLeft, setCurrentMessageLeft] = useState('');
    const [currentMessageRight, setCurrentMessageRight] = useState('');
    const [isSendingMessage, setIsSendingMessage] = useState(false);
    const [doctor, setDoctor] = useState('Dokter');
    const [patient, setPatient] = useState('Patiënt');
    const [fromLanguage, setFromLanguage] = useState('English (United Kingdom)');
    const [toLanguage, setToLanguage] = useState('Dutch (Netherlands)');
    const [isChatStarted, setIsChatStarted] = useState(false);
    const [showNavbarAndFooter, setShowNavbarAndFooter] = useState(true);
    const [confirmationModal, setConfirmationModal] = useState(false);
    const [confirmationType, setConfirmationType] = useState('');
    const [patientInputPlaceholder, setPatientInputPlaceholder] = useState('Write your message here...');
    const [eindeGesprek, setEindeGesprek] = useState(false);
    const [isRecording, setIsRecording] = useState<boolean>(false);
    const [transcription, setTranscription] = useState<string>('');
    const recognition = useRef<MediaRecorder | null>(null);
    const [technischprobleem, setTechnischProbleem] = useState('');
    const navigate = useNavigate();

    const currentDate = new Date().toISOString().split('T')[0]; // DIT MOET IK AANPASSEN NAAR EEN ALGEMENE TIJD NIET DE GEBRUIKERS KLOK TIJD

    useEffect(() => {
        setShowNavbarAndFooter(false);
    }, []);

    // Get all the data from the user
    const [profile, setProfile] = useState(null);

    useEffect(() => {
        const token = getJwtToken();

        if (token) {
            fetch(reactServer + 'account/profile', {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                    }
                    return response.json();
                })
                .then(data => {
                    setProfile(data);
                    setDoctor(data.naam)
                })
                .catch(error => {
                    console.error('Error fetching profile:', error);
                });
        }
    }, []);

    // const allPatients = ['Marcel Zwoegman', 'Martine Veenink', 'Bob de witte']; // Add your list of patients
    const allPatients = profile?.patienten?.map(patient => `${patient.naam} ${patient.surname}`) || [];


    const startChat = () => {
        if (isDoctorLoggedIn()) {

            if (profile.subscription[0] === 'lite' && profile.usage >= 10) {
                setError('Maximaal aantal gesprekken gevoerd met uw Lite account. U moet upgraden naar een premium account.');
                return;
            }

            if (profile.subscription[0] === 'no') {
                setError('U heeft momenteel geen lopend abbonement.');
                return;
            }

            // Check if the current date is not after the subscription end date
            if (profile.subscription[1] && currentDate > profile.subscription[1]) {
                setError('Uw abonnement is verlopen. Gelieve uw abonnement te vernieuwen.');
                return;
            }




            setIsChatStarted(true);

            const token = getJwtToken();

            if (token) {
                const newConversation = {
                    tag: 'temp', // You need to implement this function to generate a unique tag
                    doctor: doctor,
                    patient: patient
                };


                // First, update the user's usage count
                fetch(reactServer + 'account/update_usage', {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ user_id: profile.id }) // Include the user ID in the request body
                })
                    .then(response => {
                        if (!response.ok) {
                            throw new Error(`HTTP error! Status: ${response.status}`);
                        }
                        return response.json();
                    })
                    .then(data => {
                        // Handle success response if needed
                        console.log('Usage count updated:', data);
                        // Now, proceed to create a new conversation
                        createConversation();
                    })
                    .catch(error => {
                        console.error('Error updating usage count:', error);
                    });
            }
        }
    };

    const createConversation = () => {
        const token = getJwtToken();

        if (token) {
            const newConversation = {
                tag: 'temp', // You need to implement this function to generate a unique tag
                doctor: doctor,
                patient: patient
            };

            fetch(reactServer + 'conversation/create', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newConversation),
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                    }
                    return response.json();
                })
                .then(data => {
                    // Handle success response if needed
                    console.log('New conversation created:', data);
                    localStorage.setItem('conversationId', data.id);
                    setConversationId(data.id)
                })
                .catch(error => {
                    console.error('Error creating new conversation:', error);
                });
        }
    };


    const isDoctorLoggedIn = () => {
        return true; // Assuming the doctor is logged in
    };

    const setPatientAndLanguage = (selectedPatient: string) => {
        const foundPatient = profile?.patienten?.find((patient: any) =>
            `${patient.naam} ${patient.surname}` === selectedPatient
        );

        if (foundPatient) {
            setPatient(selectedPatient);
            setFromLanguage(foundPatient.language);
        }
    };

    const showConfirmation = (type: string) => {
        setConfirmationType(type);
        setConfirmationModal(true)
    };

    const handleConfirmation = (type: string) => {
        showConfirmation(type);
    };


    const confirmAction = () => {
        if (confirmationType === 'Einde gesprek') {
            setConfirmationModal(true);
            // Logic for ending the conversation
            setEindeGesprek(true);

            // navigate('/eindegesprek');
        } else if (confirmationType === 'Technisch probleem') {
            setTechnischProbleem('Deze functie is nog niet geimplementeerd.')
            // Logic for reporting technical problems
        } else if (confirmationType === 'Contact met tolk') {
            setTechnischProbleem('Deze functie is nog niet geimplementeerd.')
            // Logic for contacting the interpreter
        }

        setConfirmationType('');
        setConfirmationModal(false);
    };

    useEffect(() => {
        updateTranscript();
    }, [originalMessages, leftMessages, rightMessages, conversationId]);


    const updateTranscript = async () => {
        const token = getJwtToken();

        if (token) {
            try {
                const response = await fetch(reactServer + 'conversation/update_transcript', {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        transcript: originalMessages,
                        transcript_dutch: rightMessages, // Extracting text from rightMessages
                        transcript_foreign: leftMessages,
                        conversation_id: conversationId ///////////////ERROR
                    }),
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                // Handle success response if needed
                console.log('Transcript updated successfully');
            } catch (error) {
                console.error('Error updating transcript:', error);
            }
        }
    };

    const sendMessage = async (language: string) => {
        setIsSendingMessage(true);

        if (currentMessage.trim() === '') return;

        const message = {
            text: currentMessage,
            className: language === fromLanguage ? 'message-left' : 'message-right',
            mobileClass: language === fromLanguage ? 'mobile-left' : 'mobile-right',
        };

        setCurrentMessage('');
        setCurrentMessageLeft('');
        setCurrentMessageRight('');

        // Define translatedMessage before the conditional
        let translatedMessage = {
            text: '',
            className: '',
            mobileClass: ''
        };

        // Translate the message
        if (language === fromLanguage) {
            translatedMessage.text = await translateMessage(message.text, fromLanguage, toLanguage);
            translatedMessage.className = 'message-left';
            translatedMessage.mobileClass = 'mobile-right'
        } else {
            translatedMessage.text = await translateMessage(message.text, toLanguage, fromLanguage);
            translatedMessage.className = 'message-right';
            translatedMessage.mobileClass = 'mobile-left'
        }

        setIsSendingMessage(false);
        if (language === fromLanguage) {
            setleftMessages([...leftMessages, message]);
            setrightMessages([...rightMessages, translatedMessage]);
            setOriginalMessages([...originalMessages, message]);
            setallMessages([...allMessages, message, translatedMessage]);
        } else {
            setleftMessages([...leftMessages, translatedMessage]);
            setrightMessages([...rightMessages, message]);
            setOriginalMessages([...originalMessages, message]);
            setallMessages([...allMessages, message, translatedMessage]);
        }

        await updateTranscript();
    };

    useEffect(() => {
    }, [rightMessages]);


    const translateMessage = async (text: string, source_language: string, target_language: string) => {
        try {
            const response = await fetch(reactServer + 'translation/translate', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    text: text,
                    source_language: TranslateMapping[source_language],
                    target_language: TranslateMapping[target_language]
                })
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            return data.translated_text;
        } catch (error) {
            console.error("There was an error translating the text: ", error);
            return null;
        }
    };

    const handleVoiceInput = async (speaker: string) => {
        setIsSendingMessage(true);
        if (!isRecording) {
            startRecording(speaker);
        } else {
            stopRecording();
        }
    };

    const startRecording = (speaker: string) => {
        setIsRecording(true);
    
        const constraints = {
            audio: {
                sampleRate: 16000, // Desired sample rate (16 kHz)
                channelCount: 1,   // Mono audio
            }
        };
    
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices.getUserMedia(constraints)
                .then((stream) => {
                    setupMediaRecorder(stream, speaker);
                })
                .catch((error) => {
                    console.error('Error accessing microphone:', error);
                });
        } else if ((navigator as any).getUserMedia) { // Safari <= 13
            (navigator as any).getUserMedia(constraints,
                (stream) => {
                    setupMediaRecorder(stream, speaker);
                },
                (error) => {
                    console.error('Error accessing microphone:', error);
                }
            );
        } else {
            console.log('getUserMedia is not supported');
            // Handle unsupported scenario
        }
    };
    
    const setupMediaRecorder = (stream, speaker) => {
        recognition.current = new MediaRecorder(stream, { mimeType: 'audio/webm' });
    
        const audioChunks = [];
    
        recognition.current.ondataavailable = (event) => {
            audioChunks.push(event.data);
        };
    
        recognition.current.onstop = async () => {
            const audioBlob = new Blob(audioChunks, { type: 'audio/webm' });
    
            try {
                // Convert the audio blob to a data URL
                const audioUrl = URL.createObjectURL(audioBlob);
    
                // Pass the data URL to the transcode function
                const wavBlob = await transcodeAudio(audioUrl, speaker);
                transcribeAudio(wavBlob, speaker);
            } catch (error) {
                console.error('Error converting audio blob to data URL:', error);
            }
        };
    
        recognition.current.start();
    };
    

    const stopRecording = () => {
        setIsRecording(false);

        if (recognition.current && recognition.current.state !== 'inactive') {
            recognition.current.stop();
        }
    };

    const transcribeAudio = async (audioBlob: Blob, speaker: string) => {
        try {
            const token = getJwtToken();
            const formData = new FormData();
            formData.append('audio', audioBlob);
            formData.append('language', SpeechToTextMapping[fromLanguage]);
            formData.append('doctor', doctor);
            formData.append('speaker', speaker);

            console.log('Starting the transcription of the audio')

            const response = await fetch(reactServer + 'speech/transcribe', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                body: formData,
            });

            const data = await response.json();
            if (!data.transcription) {
                // Notify the user that the recording didn't work out
                console.log("Recording failed or no transcription received.");
                setTranscription("Opname niet gelukt.");
            } else {
                setTranscription("");

                const message = {
                    text: data.transcription,
                    className: speaker === 'doctor' ? 'message-right' : 'message-left', // Adjust message class based on the speaker
                    mobileClass: speaker === 'doctor' ? 'mobile-right' : 'mobile-left', // Adjust mobile class based on the speaker
                };

                const translatedMessage = {
                    text: speaker === 'doctor' ? await translateMessage(data.transcription, toLanguage, fromLanguage) : await translateMessage(data.transcription, fromLanguage, toLanguage),
                    className: speaker === 'doctor' ? 'message-right' : 'message-left', // Adjust message class based on the speaker
                    mobileClass: speaker === 'doctor' ? 'mobile-left' : 'mobile-right', // Adjust mobile class based on the speaker
                };

                setTimeout(() => {
                    // Resolve the Promise after 20 seconds
                }, 20000); // 20 seconds in milliseconds

                setIsSendingMessage(false);
                if (speaker === 'doctor') {
                    setrightMessages([...rightMessages, message]);
                    setleftMessages([...leftMessages, translatedMessage]);
                } else {
                    setrightMessages([...rightMessages, translatedMessage]);
                    setleftMessages([...leftMessages, message]);
                }
                setOriginalMessages([...originalMessages, message]);
                setallMessages([...allMessages, message, translatedMessage]);
            }
        } catch (error) {
            console.error('Error transcribing audio:', error);
        }
    };

    const handleTextToSpeech = async (message) => {
        try {
            console.log('Starting text to speech.');
            const token = getJwtToken();
            const formData = new FormData();
            console.log(message)
            formData.append('input_text', message.text);
            formData.append('voice', message.mobileClass === 'mobile-right' ? TextToSpeechMapping[toLanguage] : TextToSpeechMapping[fromLanguage]); // Default voice, change if needed
            formData.append('speed', '1'); // Default speed, change if needed

            const response = await fetch(reactServer + '/speech/text_to_speech', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                body: formData,
            });

            // Parse the response as a Blob
            const blob = await response.blob();

            // Create a URL for the Blob object
            const audioUrl = URL.createObjectURL(blob);

            // Play the audio
            const audio = new Audio(audioUrl);
            audio.play();

        } catch (error) {
            console.error('Error converting text to speech:', error);
        }
    };


    return (
        <div className="chat-page">
            {/* {!profile &&
                <div>
                    U dient ingelogd te zijn om deze pagina te bekijken
                </div>
            } */}
            {profile && !eindeGesprek && (<div>
                {!isChatStarted ? (
                    <div className="chat-setup">
                        <img src='./TolkChatLogo.png' alt="Logo" className="tolkchat-logo" />
                        <h1 className='title'>Welkom, {profile.naam}</h1>
                        <div>
                            <p>Selecteer een Patiënt om mee te chatten:</p>
                            <div className="dropdown-container">
                                <select
                                    className='selector'
                                    value={patient}
                                    onChange={(e) => setPatientAndLanguage(e.target.value)}
                                >
                                    <option value="">Selecteer Patiënt</option>
                                    {allPatients.map(patient => (
                                        <option key={patient} value={patient}>{patient}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <button className="start-button" onClick={startChat}>
                            Start de TolkChat
                        </button>
                        <button className="start-button" onClick={() => navigate('../domein')}>
                            Patiënt Toevoegen
                        </button>
                        <div className='error-message'>
                            {error}
                        </div>
                        <div className='tolkchat-space'></div>
                    </div>
                ) : (
                    <div>
                        {/* Patient info and doctor info */}
                        <div className="user-info">
                            <div className="patient-info">
                                <p className='nametext'>{patient}</p>
                                <div className='patient-language'>
                                    <p className='languagetext'>Language: </p>
                                    {/* <select
                                        className='patient-language-selector'
                                        value={fromLanguage}
                                        onChange={(e) => setFromLanguage(e.target.value)}
                                    >
                                        {allLanguages.map(lang => (
                                            <option key={lang} value={lang}>{lang}</option>
                                        ))}
                                    </select> */}
                                    <LanguageSelector
                                        allLanguages={allLanguages}
                                        fromLanguage={fromLanguage}
                                        setFromLanguage={setFromLanguage}
                                    />
                                </div>
                            </div>
                            <div className="doctor-info">
                                <p className='nametext'>{profile.naam}</p>
                                <div>
                                    <p className='languagetext'>Language: Nederlands</p>
                                    <p className='languagetext-mobile nametext'>Nederlands</p>
                                </div>
                            </div>
                        </div>
                        {/* Your chat interface */}
                        <div className="chat-interface chat-desktop">
                            <div className="chat-screen left-chat">
                                {leftMessages.map((message, index) => (
                                    <div key={index} className={message.className}>
                                        {message.text}
                                        {Object.keys(TextToSpeechMapping).includes(fromLanguage) ? (
                                            <img
                                                className="speaker"
                                                src="/speaker.png"
                                                alt="speaker"
                                                onClick={() => handleTextToSpeech(message)}
                                            />
                                        ) : (
                                            <span>&nbsp;</span>
                                        )}


                                    </div>
                                ))}
                                {isSendingMessage && <div className="message-left"><span className="message-placeholder">. . .</span></div>} {/* Placeholder message */}
                            </div>
                            <div className="chat-screen right-chat">
                                {rightMessages.map((message, index) => (
                                    <div key={index} className={message.className}>
                                        {message.text}
                                        <img className="speaker" src="/speaker.png" alt="speaker" onClick={() => handleTextToSpeech(message)} />
                                    </div>
                                ))}
                                {isSendingMessage && <div className="message-right"><span className="message-placeholder">. . .</span></div>} {/* Placeholder message */}

                            </div>
                        </div>

                        {/* Mobile version */}
                        <div className="chat-interface chat-mobile">
                            <div className="chat-screen left-chat">
                                {allMessages.map((message, index) => (
                                    <div key={index} className={message.className + ' ' + message.mobileClass}>
                                        {message.text}
                                        {Object.keys(TextToSpeechMapping).includes(fromLanguage) ? (
                                            <img
                                                className="speaker"
                                                src="/speaker.png"
                                                alt="speaker"
                                                onClick={() => handleTextToSpeech(message)}
                                            />
                                        ) : (
                                            <span>&nbsp;</span>
                                        )}
                                    </div>
                                ))}
                            </div>

                        </div>

                        {/* Input container */}
                        <div className="input-container input-desktop">

                            <textarea
                                className="input-message"
                                placeholder={patientInputPlaceholder}
                                value={currentMessageLeft}
                                onChange={(e) => { setCurrentMessage(e.target.value); setCurrentMessageLeft(e.target.value) }}
                                style={{ width: '38%', height: '60px', fontSize: '16px', marginRight: '30px' }}
                            />
                            <textarea
                                className="input-message"
                                placeholder="Start het gesprek met uw patiënt hier..."
                                value={currentMessageRight}
                                onChange={(e) => { setCurrentMessage(e.target.value); setCurrentMessageRight(e.target.value) }}
                                style={{ width: '38%', height: '60px', fontSize: '16px', marginLeft: '30px' }}
                            />
                        </div>

                        {/* Mobile input container */}
                        <div className="input-container input-mobile">

                            <textarea
                                className="input-message"
                                placeholder={patientInputPlaceholder}
                                value={currentMessageLeft}
                                onChange={(e) => { setCurrentMessage(e.target.value); setCurrentMessageLeft(e.target.value) }}
                                style={{ width: '85%', height: '60px', fontSize: '16px' }}
                            />
                        </div>

                        {/* Alignment buttons */}
                        <div className="alignment-buttons">
                            {transcription}
                            <div>
                                <img
                                    className={`microphone ${isRecording ? "recording" : ""}`}
                                    src={isRecording ? "/recording.png" : "/microphone.png"}
                                    alt="microphone"
                                    onClick={() => handleVoiceInput('patient')}
                                />

                                <button className="send-button left-button" onClick={() => sendMessage(fromLanguage)}>{patient} Knop</button>
                            </div>
                            <div>
                                <button className="send-button right-button" onClick={() => sendMessage(toLanguage)}>{doctor}</button>
                                <img
                                    className={`microphone ${isRecording ? "recording" : ""}`}
                                    src={isRecording ? "/recording.png" : "/microphone.png"}
                                    alt="microphone"
                                    onClick={() => handleVoiceInput('doctor')}
                                />
                            </div>
                        </div>


                        <button className="samenvatten-button" onClick={() => handleConfirmation('Contact met tolk')}>
                            Twee schermen
                        </button>

                        <button className="samenvatten-button" onClick={() => handleConfirmation('Einde gesprek')}>
                            Einde gesprek
                        </button>

                        <button className="samenvatten-button technisch" onClick={() => handleConfirmation('Technisch probleem')}>
                            Technisch probleem
                        </button>

                        {/* Confirmation modal */}
                        {technischprobleem && (
                        <div>{technischprobleem}</div>)}
                        {confirmationModal && (
                            <div className="confirmation-modal">
                                <p>Weet je zeker dat je dit gesprek wilt afsluiten?</p>
                                <button className="samenvatten-button" onClick={confirmAction}>Bevestigen</button>
                                <button className="samenvatten-button" onClick={() => setConfirmationModal(false)}>Annuleren</button>
                            </div>
                        )}
                    </div>
                )}
            </div>)}
            {profile && eindeGesprek &&
                <div>
                    <Eindscherm
                        conversationId={conversationId.toString()}
                        transcript={originalMessages}
                        transcript_dutch={rightMessages}
                        transcript_foreign={leftMessages}
                    />
                </div>}
        </div>
    );
};

export default ChatPage;
