// abonnement.tsx
import './abbonement.css'
import React, { useState } from 'react';
import '../App.css'; // Import the CSS file

import checkingGif from '../images/check.gif';

// Access the server variable
import { getJwtToken } from '../auth';
import config from '../config';
const reactServer = config.reactServer;

const Abonnement: React.FC = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [returnMessage, setReturnMessage] = useState('');
  const [selectedSubscription, setSelectedSubscription] = useState<string>('');

  const handleSubscriptionClick = (subscriptionType: string) => {
    setSelectedSubscription(subscriptionType);
  };

  const handleRequestQuote = async () => {
    if (name && email && message && selectedSubscription) {
      const requestData = {
        name: name,
        email: email,
        title: `Offerte aanvraag voor: ${selectedSubscription}`, // You can customize the title
        message: message,
        message_type: 'offerte', // You can adjust this based on your server logic
        details: [selectedSubscription], // You can customize the details
      };

      const token = getJwtToken();

      try {
        const response = await fetch(reactServer + '/mail/send', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify(requestData),
        });

        if (!response.ok) {
          throw new Error('Failed to send mail');
        }

        // Mail sent successfully, handle the response as needed
        console.log('Mail sent successfully');
        setReturnMessage('Uw offerte is aangevraagd.')
      } catch (error) {
        console.error('Error sending mail:', error);
        setReturnMessage('Fout bij het aanvragen van de offerte.')
      }
    } else {
      // Handle case when form fields are not filled
      console.log('Please fill all form fields');
      setReturnMessage('Vul graag alle velden in.')
    }
  };


  return (
    <div className="abonnement-page-container">
      {!(returnMessage === 'Uw offerte is aangevraagd.') &&
        <div className="abonnement-page-container">
          <h1 className='title'>Vraag uw abbonement aan</h1>
          <div className="subscription-boxes">
            {/* Maandelijk Abbonement Box */}
            <div
              className={`subscription-box ${selectedSubscription === 'monthly' ? 'active' : ''}`}
              onClick={() => handleSubscriptionClick('monthly')}
            >
              <h3>Maandelijks Abbonement</h3>
              <p>€75 per maand</p>
            </div>

            {/* Jaar abbonement Box */}
            <div
              className={`subscription-box ${selectedSubscription === 'yearly' ? 'active' : ''}`}
              onClick={() => handleSubscriptionClick('yearly')}
            >
              <h3>Jaar abbonement</h3>
              <p>€67,50 per maand (-10%)</p>
            </div>

            {/* Local Program Installation Box */}
            <div
              className={`subscription-box ${selectedSubscription === 'localProgram' ? 'active' : ''}`}
              onClick={() => handleSubscriptionClick('localProgram')}
            >
              <h3>Lokale installatie</h3>
              <p>Neem contact op voor pricing</p>
            </div>
          </div>

          <div className="request-form">

            {selectedSubscription && (
              <div className="form-inputs">
                <input
                  type="text"
                  placeholder="Naam"
                  value={name}
                  onChange={(e) => setName(e.target.value)} // Update name state
                />
                <input
                  type="text"
                  placeholder="E-mail adres"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)} // Update email state
                />
                <textarea
                  placeholder="Bericht (optioneel)"
                  rows={4}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)} // Update message state
                />
                <button className="request-quote-button" onClick={handleRequestQuote}>Offerte Aanvragen</button>

                <div className='offertemessage'>{returnMessage}</div>
              </div>
            )}
          </div>
        </div>}
      {returnMessage === 'Uw offerte is aangevraagd.' &&
        <div>
          <img className='check_gif' src={checkingGif} alt="Submitting..." />
          <div className='offertemessage'>{returnMessage}</div>
        </div>
      }
    </div>
  );
};

export default Abonnement;