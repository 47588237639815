// src/views/WaaromPage.tsx

import React from 'react';

const WaaromPage: React.FC = () => {
    return (
        <div className="waarom-page">
            <h1 className="title">Waarom Wellcom?</h1>
            <div className="reason">
                <h2 className="reason-title">1. Nauwkeurige Vertalingen</h2>
                <p className="reason-description">
                    Wellcom zorgt voor nauwkeurige medische vertalingen, essentieel voor correcte diagnose en behandeling.
                </p>
            </div>
            <div className="reason">
                <h2 className="reason-title">2. Beveiliging van Patiëntgegevens</h2>
                <p className="reason-description">
                    De app werkt lokaal om de privacy en veiligheid van patiëntgegevens te waarborgen.
                </p>
            </div>
            <div className="reason">
                <h2 className="reason-title">3. Makkelijk in Gebruik</h2>
                <p className="reason-description">
                    Een gebruiksvriendelijke interface zorgt voor een naadloze ervaring in hectische medische omgevingen.
                </p>
            </div>
            <div className="reason">
                <h2 className="reason-title">4. Ondersteund door Medische Experts</h2>
                <p className="reason-description">
                    Ontwikkeld met inbreng van medische professionals om te voldoen aan klinische behoeften.
                </p>
            </div>
            <div className="reason">
                <h2 className="reason-title">5. Multiculturele Communicatie</h2>
                <p className="reason-description">
                    Verbetert communicatie met patiënten van diverse achtergronden, bevordert inclusieve zorg.
                </p>
            </div>
            {/* Additional content */}
        </div>
    );
};

export default WaaromPage;
