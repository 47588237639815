// src/views/demo.tsx

import React from 'react';
import './infopages.css'

const InfoPage: React.FC = () => {
    return (
        <div className="faq-page">
            <h1 className="title">Demo Pilot</h1>
            <div className="reason">
                <h2 className="reason-title">Onder ontwikkeling</h2>
                <p className="reason-description">
                    Deze pagina is onder ontwikkeling, binnekort zal hier een tutorial komen te staan over de werking van onze tools.
                </p>
            </div>

        </div>
    );
};

export default InfoPage;
